import { useEffect, useRef, useState } from 'react';

export function useIntersectionObserver<T extends Element = HTMLElement>() {
  const ref = useRef<T | null>(null);
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInViewport(entry.isIntersecting);
      },
      {
        root: null, // To watch for intersection relative to the device's viewport, specify null for the root option.
        threshold: 0, // Consider visible if at least 1px is in viewport
      },
    );

    observer.observe(el);

    return () => {
      observer.disconnect();
    };
  }, []);

  return { ref, isInViewport };
}
